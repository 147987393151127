export const makeAttempt =
    <ErrorType = unknown>() =>
    <Data>(
        p: Promise<Data>
    ): Promise<readonly [Data, undefined] | readonly [undefined, ErrorType]> =>
        p
            .then((res) => [res, undefined] as const)
            .catch((err) => [undefined, err] as const);

export const attempt = makeAttempt();
