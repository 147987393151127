import React, { type FC } from 'react';
import { Box, type BoxProps, Row } from 'bb/ui';

export type UspContainerProps = BoxProps<'section'>;

export const UspContainer: FC<UspContainerProps> = ({
    children,
    ...restProps
}) => (
    <Box {...restProps} as="section">
        <Row flexWrap="wrap" alignItems="stretch">
            {children}
        </Row>
    </Box>
);
