/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classnames from 'classnames';
import { Text } from 'bb/i18n';
import commonCss from 'bb/style/common.module.scss';
import {
    type PolymorphicComponent,
    type PolymorphicRef,
    type MarginProps
} from 'bb/ui';
import { BookBeatHeaderAnimatedChildren } from './BookBeatHeaderAnimatedChildren';

export type BookBeatHeaderProps<TElement extends React.ElementType = 'h2'> =
    PolymorphicComponent<
        TElement,
        {
            textKey: string;
            values?: React.ReactNode | string;
            additionalValues?: {
                [key: string]: string;
            };
            fluid?: boolean;
            withAnimation?: boolean;
            testId?: string;
            className?: string;
        } & MarginProps
    >;

const BookBeatHeaderComponent = (
    props: BookBeatHeaderProps<'h2'>,
    ref?: PolymorphicRef<'h2'>
) => {
    const {
        textKey,
        values,
        additionalValues,
        fluid = true,
        withAnimation,
        testId,
        as = 'h2',
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop = 'none'
    } = props;

    const commonProps = {
        ref,
        as,
        t: textKey,
        center: true,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        margin,
        testId,
        values: additionalValues
    };

    // Children needs to be array for it to work being passed to
    // the Text component.
    const children = [
        <Text
            key={0}
            as="span"
            variant="h1Secondary"
            marginBottom="none"
            data-optimizely="bookbeat-header-1"
            className={classnames(
                commonCss.breakWord,
                fluid && commonCss.headerFluid
            )}
        >
            {values}
        </Text>,
        <Text
            key={1}
            center
            marginTop="none"
            as="span"
            variant="h1Primary"
            data-optimizely="bookbeat-header-2"
            className={classnames(
                commonCss.breakWord,
                fluid && commonCss.headerFluid
            )}
        >
            {values}
        </Text>
    ];
    // Only use Provider if we need the animation.
    // Otherwise we add intersectionObserver for no use.
    return withAnimation ? (
        <BookBeatHeaderAnimatedChildren {...commonProps} inlineChildren>
            {children}
        </BookBeatHeaderAnimatedChildren>
    ) : (
        <Text {...commonProps}>{children}</Text>
    );
};

export const BookBeatHeader = React.forwardRef(BookBeatHeaderComponent) as <
    TElement extends React.ElementType
>(
    props: BookBeatHeaderProps<TElement>
) => JSX.Element;
