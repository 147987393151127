import React from 'react';
import classNames from 'classnames';
import { type PropsWithChildrenAndClassName } from 'bb/ui';
import css from './ActionLayout.module.scss';

export type ActionLayoutProps = PropsWithChildrenAndClassName;

export const ActionLayout = ({ children, className }: ActionLayoutProps) => (
    <div className={classNames(css.root, className)}>{children}</div>
);
