// @ts-strict-ignore
import React from 'react';
import { type CampaignResponse, type PriceRule } from 'bb/campaigns/types';
import { isFixedOrRelative, isPercentage } from 'bb/campaigns/utils';
import {
    usePeriod,
    useDaysPeriod,
    useTranslation,
    type TextProps
} from 'bb/i18n';
import { useFormatHandler } from 'bb/i18n/useFormatAmount';
import { type PolymorphicComponent } from 'bb/ui';
import { getDateFromNow } from 'bb/utils';
import { DiscountText } from './DiscountText';

export type PriceRuleTextProps<TElementType extends React.ElementType = 'p'> =
    PolymorphicComponent<
        TElementType,
        {
            priceRule?: PriceRule | CampaignResponse['priceRules'][number];
            dataOptimizelyId?: string;
            daysFromNow?: boolean;
            nrOfDays?: number;
            hourLimit?: number;
            displayPeriodInMonths?: boolean;
        } & TextProps<TElementType>
    >;

export const PriceRuleText = ((props: PriceRuleTextProps<'p'>) => {
    const {
        priceRule,
        values,
        daysFromNow,
        nrOfDays = 0,
        hourLimit,
        displayPeriodInMonths = false,
        t: textKey,
        ...restProps
    } = props;
    const { lifeTimeInDays, productDiscount, type } = priceRule || {};

    const { t } = useTranslation(['registration', 'common', 'subscription']);

    const dynamicPeriod = usePeriod({ days: nrOfDays });
    const daysPeriod = useDaysPeriod(nrOfDays);
    const period = displayPeriodInMonths ? dynamicPeriod : daysPeriod;

    const dynamicDiscountPeriod = usePeriod({ days: lifeTimeInDays });
    const daysDiscountPeriod = useDaysPeriod(lifeTimeInDays);
    const discountPeriod = displayPeriodInMonths
        ? dynamicDiscountPeriod
        : daysDiscountPeriod;

    const campaignEndDate =
        daysFromNow && lifeTimeInDays
            ? t('common:date', {
                  date: getDateFromNow(lifeTimeInDays + nrOfDays)
              })
            : '';

    const amountFormater = useFormatHandler();

    let discount = '';

    if (isFixedOrRelative(type)) {
        discount = amountFormater(productDiscount);
    } else if (isPercentage(type)) {
        discount = t('common:percentage', {
            amount: productDiscount
        });
    }

    const additionalValues = {
        ...values,
        discountPeriod: discountPeriod?.period,
        period: period?.period,
        periodLength: period?.periodValue,
        campaignEndDate,
        hourLimit,
        discount
    };

    return (
        <DiscountText
            values={additionalValues}
            {...restProps}
            t={type ? `${textKey}-${type}` : textKey}
        />
    );
}) as <TElementType extends React.ElementType = 'p'>(
    props: PriceRuleTextProps<TElementType>
) => JSX.Element;
