import React from 'react';
import { type Color } from 'bb/style/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import css from './emptyBlock.module.scss';

export const EmptyBlock = ({ color = 'primary-white' }: { color?: Color }) => (
    <div
        className={css.base}
        style={makeCSSVariableFromColor(
            '--empty-block-background-color',
            color
        )}
    />
);
