/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';

export type ExpandableProps = {
    isOpen: boolean;
} & React.ComponentProps<'div'>;

const Expandable: React.FC<ExpandableProps> = ({
    isOpen,
    children,
    ...restProps
}) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const contentHeight = contentRef.current?.scrollHeight ?? 0;

    const { height } = useSpring({
        from: { height: 0 },
        to: {
            height: isOpen ? contentHeight : 0
        }
    });

    useEffect(() => {
        if (isOpen) {
            height.start({
                to: contentHeight
            });
        }
    }, [isOpen, height, contentHeight]);

    return (
        <div {...restProps}>
            <animated.div style={{ overflow: 'hidden', height }}>
                <div ref={contentRef}>{children}</div>
            </animated.div>
        </div>
    );
};

export default Expandable;
