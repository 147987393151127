import React from 'react';
import { Text, type TextProps } from 'bb/i18n';
import { type PolymorphicComponent } from 'bb/ui';

export type DiscountProps<TElementType extends React.ElementType = 'p'> =
    PolymorphicComponent<
        TElementType,
        {
            dataOptimizelyId?: string;
        } & TextProps<TElementType>
    >;

export const DiscountText = ((props: DiscountProps<'p'>) => {
    const { t, dataOptimizelyId, values, children, ...restProps } = props;
    return (
        <Text
            {...restProps}
            t={t}
            data-optimizely={dataOptimizelyId}
            values={{
                ...values
            }}
            {...(dataOptimizelyId && {
                'data-optimizely': dataOptimizelyId
            })}
        >
            {children}
        </Text>
    );
}) as <TElementType extends React.ElementType = 'p'>(
    props: DiscountProps<TElementType>
) => JSX.Element;
