import React, { type FC } from 'react';
import { Text } from 'bb/i18n';
import { useShareTracker } from 'bb/tracker';
import { DeprecatedButton, ShareIcon, type ButtonVariant } from 'bb/ui';
import { attempt } from './async';

export type ShareButtonProps = {
    url: string;
    text?: string;
    type?: ButtonVariant;
    trackEvent?: string;
    bold?: boolean;
    shareKey?: string;
};

export const ShareButton: FC<ShareButtonProps> = ({
    url,
    text,
    type = 'primary',
    trackEvent,
    bold = false,
    shareKey
}) => {
    const tracker = useShareTracker();

    const onClick = async () => {
        await attempt(navigator.share({ title: 'BookBeat', url, text }));
        if (!trackEvent) return;

        try {
            tracker.trackShare(trackEvent, 'success');
        } catch {
            tracker.trackShare(trackEvent, 'failed');
        }
    };

    return (
        <DeprecatedButton
            fluid
            variant={type}
            onClick={onClick}
            data-testid="share-button"
            icon={<ShareIcon />}
            iconSize="small"
        >
            <Text as="span" t={shareKey || 'common:shareButton'} bold={bold} />
        </DeprecatedButton>
    );
};
