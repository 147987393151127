import React from 'react';
import { Box, type BoxProps } from 'bb/ui/Box';
import { type PolymorphicRef, type PolymorphicComponent } from 'bb/ui/types';

export type PaddedBoxProps<TElementType extends React.ElementType = 'div'> =
    PolymorphicComponent<TElementType, Omit<BoxProps<TElementType>, 'padding'>>;

const PaddedBoxComponent = (
    props: PaddedBoxProps<'div'>,
    ref?: PolymorphicRef<'div'>
) => {
    const { children, as = 'div', ...restProps } = props;

    return (
        <Box
            {...restProps}
            as={as}
            ref={ref}
            padding={{ xxs: [8, 4, 8, 4], sm: [12, 6, 12, 6] }}
        >
            {children}
        </Box>
    );
};

/**
 * A composition of the `Box` component with a standard set of
 * padding added. Commonly used as a section-type component.
 */
export const PaddedBox = React.forwardRef(PaddedBoxComponent) as <
    TElementType extends React.ElementType = 'div'
>(
    props: PaddedBoxProps<TElementType>
) => JSX.Element;
