import React, { type FC } from 'react';
import { Text } from 'bb/i18n';
import { Box, type BoxProps } from 'bb/ui';
import css from './usp.module.scss';

type UspItemProps = {
    header?: string | null;
    text?: string | null;
    headerValues?: React.ReactNode | string;
    headerValuesObject?: Record<string, unknown>;
    textValues?: React.ReactNode | string;
    textValuesObject?: Record<string, unknown>;
} & BoxProps;

export const UspItem: FC<UspItemProps> = ({
    header,
    text,
    headerValues,
    headerValuesObject,
    textValues,
    margin,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    textValuesObject,
    ...restProps
}) => (
    <Box
        className={css.leftBorder}
        margin={margin}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        paddingLeft="extraSmall"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
    >
        <Text
            as="h2"
            size="large"
            marginBottom="none"
            marginTop="none"
            t={header}
            values={headerValuesObject}
            data-optimizely="usp-item-header"
        >
            {headerValues}
        </Text>
        <Text
            values={textValuesObject}
            marginTop="extraSmall"
            t={text}
            data-optimizely="usp-item-text"
        >
            {textValues}
        </Text>
    </Box>
);
