// @ts-strict-ignore
import React, { type FC, useEffect, useState } from 'react';
import { Text } from 'bb/i18n';
import { Box, Card, Spinner, type SpinnerProps, Display } from 'bb/ui';
import css from './spinnerWithFeedback.module.scss';

const TIMEOUT_IN_MS = 5000;

export const SpinnerWithFeedback: FC<
    SpinnerProps & { translation: string }
> = ({ show, nested = true, translation, children, ...props }) => {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (show && !timeout) {
            timeout = setTimeout(() => {
                setShowMessage(true);
            }, TIMEOUT_IN_MS);
        } else {
            timeout && clearTimeout(timeout);
            setShowMessage(false);
        }

        return () => timeout && clearTimeout(timeout);
    }, [show]);

    return (
        <Box className={css.box}>
            <Spinner
                nested={nested}
                show={show}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            >
                {children}
            </Spinner>
            <Display on={showMessage}>
                <Box className={css.feedback}>
                    <Card className={css.card} padding="small" withBorder>
                        <Text
                            center
                            marginBottom="extraSmall"
                            t={translation}
                        />
                        <Spinner show size="small" nested />
                    </Card>
                </Box>
            </Display>
        </Box>
    );
};
