import React, { type FunctionComponent } from 'react';
import { type BBApiTypes } from 'bb/api/types';
import { Text, usePeriod, useTranslation } from 'bb/i18n';
import { type GiftcardDetails } from 'bb/registration/types';

type GiftcardsSubscriptionTextProps = {
    textKey: string;
    values?: Record<string, unknown>;
    center?: boolean;
    giftcard: GiftcardDetails | BBApiTypes['ApiGiftCard'];
};

export const GiftcardSubscriptionText: FunctionComponent<
    GiftcardsSubscriptionTextProps
> = ({ textKey, values, center, giftcard }) => {
    useTranslation(['registration', 'common', 'subscription']);
    const period = usePeriod({
        days: giftcard?.validdays,
        months: giftcard?.validmonths
    });

    const subscription = giftcard?.subscriptiontypedisplayname;

    return (
        <Text
            center={center}
            t={`${textKey}-giftcard`}
            values={{
                ...values,
                period: period?.period,
                subscription
            }}
        />
    );
};
