import React from 'react';
import { useApi } from 'bb/api/browser/useApi';
import { AmountPerMonth } from 'bb/i18n';
import { findCheapestProduct } from 'bb/subscription/findCheapestProduct';

export const StartAtPrice = () => {
    const { data: subscriptionProducts } = useApi(
        '/api/subscription/products',
        {
            fixed: true
        }
    );

    const cheapestProduct = findCheapestProduct(subscriptionProducts?.products);

    return <AmountPerMonth amount={cheapestProduct?.price} />;
};
