/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Expandable, { type ExpandableProps } from './Expandable';

export type SelfClosingExpandableProps = {
    /**
     * Time in milliseconds
     */
    closeAfter: number;
} & Omit<ExpandableProps, 'isOpen'>;

export const SelfClosingExpandable = ({
    closeAfter,
    ...restProps
}: SelfClosingExpandableProps) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);

        setTimeout(() => {
            setIsOpen(false);
        }, closeAfter);
    }, [closeAfter]);

    return <Expandable isOpen={isOpen} {...restProps} />;
};
