import React from 'react';
import classNames from 'classnames';
import { type Color } from 'bb/style/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { CheckIcon } from 'bb/ui/Icons/svgs';
import css from './successAnimation.module.scss';

export type SuccessAnimationProps = {
    bgColor?: Color;
} & React.ComponentProps<'div'>;

export const SuccessAnimation = ({
    bgColor = 'secondary-green',
    className,
    ...restProps
}: SuccessAnimationProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={classNames(css.root, className)} {...restProps}>
        <div
            className={css.inner}
            style={makeCSSVariableFromColor(
                '--success-animation-background-color',
                bgColor
            )}
        >
            <div className={classNames(css.lightInnerBox, css.innerBox)} />
            <div className={classNames(css.darkInnerBox, css.innerBox)} />
            <CheckIcon />
        </div>
    </div>
);
